import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoaded, isEmpty, firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Authorized from './Authorized';
import Loading from './Loading';

class AppContainer extends React.Component {
    componentDidMount() {
        //
    }

    render() {
        if (isLoaded(this.props.auth)) {
            if (isEmpty(this.props.auth)) {
                return (
                    <div>
                        Please log in
                        <button
                            onClick={() =>
                                this.props.firebase.login({ provider: 'google', type: 'popup' })
                            }
                        >
                            Login With Google
                        </button>
                    </div>
                );
            }
        } else {
            return <Loading />;
        }
        return <Authorized>{this.props.children}</Authorized>;
    }
}

AppContainer.propTypes = {
    auth: PropTypes.shape({
        displayName: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    firebase: PropTypes.shape({
        login: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    user: PropTypes.shape({
        value: PropTypes.shape({
            isAdmin: PropTypes.bool,
        }),
    }),
};
AppContainer.defaultProps = {
    user: {
        value: {
            isAdmin: false,
        },
    },
};
const enhance = compose(
    // firestoreConnect(),
    firebaseConnect(),
    connect(({ firebase }, b) => ({
        auth: firebase.auth,
        firebase: b.firebase,
    })),
);

export default enhance(AppContainer);
