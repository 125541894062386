import React from 'react';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { getUser, emitToSocket } from '../helpers';
import { getTodayOrders, getTodayOrdersStatus } from '../store/selectors';
import Loading from './Loading';

export const getCrazyName = (data) => {
    let name = data.burger;
    if (data.bulka_zytnia === 1) {
        name = `${name} + bulka zytnia`;
    }
    if (data.boczki === 2) {
        name = `${name} + 2x boczek`;
    }
    if (data.boczki === 1) {
        name = `${name} + boczek`;
    }
    if (data.kotlet === 1) {
        name = `${name} + kotlet`;
    }
    if (data.jalapeno === 1) {
        name = `${name} + jalapeno`;
    }
    if (data.pieczarki === 1) {
        name = `${name} + pieczarki`;
    }
    if (data.nachos === 1) {
        name = `${name} + nachos`;
    }
    if (data.ogorek === 1) {
        name = `${name} + ogorek`;
    }
    if (data.panierowany_ser === 1) {
        name = `${name} + panierowany ser`;
    }

    if (data.meat === 'cheese') {
        name = `${name} + ser zamiast mięsa`;
    }
    if (data.meat === 'no-meat') {
        name = `${name} + bez mięsa`;
    }
    if (data.meat === 'hempeat') {
        name = `${name} + hempeat`;
    }
    return name;
};
const TopOrderBar = (props) => {
    const [checked, setTodayOrdersStatus] = React.useState(props.todayOrdersStatus === '1');
    React.useEffect(() => {
        if (props.todayOrdersStatus !== undefined) {
            setTodayOrdersStatus(props.todayOrdersStatus === '1');
        }
    }, [props.todayOrdersStatus]);
    if (props.todayOrdersStatus === undefined) {
        return null;
    }
    return (
        <div className="orderingTopBar">
            <div>
                <button type="button" onClick={() => emitToSocket('getTodayOrders')}>
                    Download current orders
                </button>
            </div>
            <div>
                Is ordering turned on:{' '}
                <Toggle
                    checked={checked}
                    onChange={(e) => {
                        setTodayOrdersStatus(e.target.checked);
                        emitToSocket('setOrdersStatus', {
                            status: e.target.checked,
                        });
                    }}
                />
            </div>
        </div>
    );
};

const Orders = (props) => {
    React.useEffect(() => {
        setTimeout(() => emitToSocket('getTodayOrders'), 500);
        setTimeout(() => emitToSocket('getTodayOrders'), 2500);
        setTimeout(() => emitToSocket('getOrdersStatus'), 500);
        setTimeout(() => emitToSocket('getOrdersStatus'), 2500);
    }, []);
    if (!isLoaded(props.users) || !isLoaded(props.user) || !isLoaded(props.user.value)) {
        return <Loading />;
    }
    if (!props.user.value.isAdmin) {
        props.history.push('/');
        return <div className="orders">Nie jestes adminem dziku</div>;
    }
    if (!props.todayOrders || props.todayOrders === null) {
        return <Loading />;
    }
    if (props.todayOrders.totalNumberOfBurgers === 0) {
        return (
            <div className="orders">
                <TopOrderBar todayOrdersStatus={props.todayOrdersStatus} />
                No orders today :(
            </div>
        );
    }
    const users = {};
    props.users.forEach((user) => {
        users[user.key] = {
            name: user.value.displayName,
            email: user.value.email,
        };
    });
    const friesMiejsceLength = props.todayOrders.friesMiejsce.reduce((prev, curr) => {
        if (+curr.halffries === 1) {
            return prev + 0.5;
        }
        return prev + 1;
    }, 0);
    const batatyFriesMiejsceLength = props.todayOrders.batatyFriesMiejsce.reduce((prev, curr) => {
        if (+curr.halffries === 1) {
            return prev + 0.5;
        }
        return prev + 1;
    }, 0);
    const cheeseChivesFriesMiejsceLength = props.todayOrders.cheeseChivesFriesMiejsce.reduce((prev, curr) => {
        if (+curr.halffries === 1) {
            return prev + 0.5;
        }
        return prev + 1;
    }, 0);
    return (
        <div className="orders">
            <TopOrderBar todayOrdersStatus={props.todayOrdersStatus} />
            <h1>Orders</h1>
            <p>Today there are {props.todayOrders.totalNumberOfBurgers} burgers</p>
            {props.todayOrders.uwagi.length > 0 && (
                <p>
                    <strong>UWAGI</strong>
                </p>
            )}
            {props.todayOrders.uwagi.map((uwaga) => (
                <p key={uwaga.id}>
                    {users[uwaga.user].name} - {uwaga.uwagi}
                </p>
            ))}
            <h2>SUM</h2>
            <p>
                <strong>Na miejscu:</strong>
            </p>
            <table>
                <thead>
                    <tr>
                        <th>Ilosc</th>
                        <th>Burger</th>
                    </tr>
                </thead>
                <tbody>
                    {props.todayOrders.normalBurgersSumMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{data.cunt}x</td>
                                <td>{data.burger}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.crazyBurgersSumMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{data.cunt}x</td>
                                <td>{getCrazyName(data)}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.friesMiejsce.length > 0 && (
                        <tr>
                            <td>{friesMiejsceLength}x</td>
                            <td>Frytki</td>
                        </tr>
                    )}  {props.todayOrders.batatyFriesMiejsce.length > 0 && (
                        <tr>
                            <td>{batatyFriesMiejsceLength}x</td>
                            <td>Sweet potatoes fries</td>
                        </tr>
                    )}
                    {props.todayOrders.cheeseChivesFriesMiejsce.length > 0 && (
                        <tr>
                            <td>{cheeseChivesFriesMiejsceLength}x</td>
                            <td>Cheese, grilled bacon, chives fries</td>
                        </tr>
                    )}
                    {props.todayOrders.cola.length > 0 && (
                        <tr>
                            <td>{props.todayOrders.cola.length}x</td>
                            <td>Cola</td>
                        </tr>
                    )}
                    {props.todayOrders.colazero.length > 0 && (
                        <tr>
                            <td>{props.todayOrders.colazero.length}x</td>
                            <td>Cola zero</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <p>
                <strong>Na wynos:</strong>
            </p>
            <table>
                <thead>
                    <tr>
                        <th>Ilosc</th>
                        <th>Burger</th>
                    </tr>
                </thead>
                <tbody>
                    {props.todayOrders.normalBurgersSumWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{data.cunt}x</td>
                                <td>{data.burger}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.crazyBurgersSumWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{data.cunt}x</td>
                                <td>{getCrazyName(data)}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.friesWynos.length > 0 && (
                        <tr>
                            <td>{props.todayOrders.friesWynos.length}x</td>
                            <td>Frytki</td>
                        </tr>
                    )}
                    {props.todayOrders.batatyFriesWynos.length > 0 && (
                        <tr>
                            <td>{props.todayOrders.batatyFriesWynos.length}x</td>
                            <td>Sweet potatoes fries</td>
                        </tr>
                    )}
                    {props.todayOrders.cheeseChivesFriesWynos.length > 0 && (
                        <tr>
                            <td>{props.todayOrders.cheeseChivesFriesWynos.length}x</td>
                            <td>Cheese, grilled bacon, chives fries</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h2>Detailed</h2>
            <p>Na miejscu</p>
            <table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Burger</th>
                    </tr>
                </thead>
                <tbody>
                    {props.todayOrders.normalBurgersMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>{data.burger}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.crazyBurgersMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>{getCrazyName(data)}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.friesMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Frytki * {data.halffries ? '0.5' : '1'}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.batatyFriesMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Sweet potatoes fries * {data.halffries ? '0.5' : '1'}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.cheeseChivesFriesMiejsce.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Cheese, grilled bacon, chives fries * {data.halffries ? '0.5' : '1'}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.cola.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Cola</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.colazero.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Cola zero</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <p>Na wynos</p>
            <table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Burger</th>
                    </tr>
                </thead>
                <tbody>
                    {props.todayOrders.normalBurgersWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>{data.burger}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.crazyBurgersWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>{getCrazyName(data)}</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.friesWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Frytki</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.batatyFriesWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Sweet potatoes fries</td>
                            </tr>
                        );
                    })}
                    {props.todayOrders.cheeseChivesFriesWynos.map((data) => {
                        return (
                            <tr key={data.id}>
                                <td>{users[data.user].name} - </td>
                                <td>Cheese, grilled bacon, chives fries</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
Orders.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
        isAdmin: PropTypes.bool,
    }),
};
Orders.defaultProps = {
    users: [],
    user: {
        isAdmin: false,
    },
};
const enhance = compose(
    firebaseConnect(['users']),
    connect((state) => ({
        users: state.firebase.ordered.users,
        auth: state.firebase.auth,
        user:
            state.firebase.ordered.users &&
            getUser(state.firebase.ordered.users, state.firebase.auth.uid),
        todayOrders: getTodayOrders(state),
        todayOrdersStatus: getTodayOrdersStatus(state),
    })),
);
export default withRouter(enhance(Orders));
