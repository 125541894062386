import React from 'react';
import { isLoaded, firebaseConnect, isEmpty } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { formatDate, emitToSocket } from '../helpers';
import { getIOweSomeone, getSomeoneOwesMe, getIsLoaded } from '../store/selectors';
import Loading from './Loading';

class DebtsList extends React.Component {
    groupByPerson = (debts, key) => {
        const debtsList = {};
        debts.forEach(debt => {
            if (!debtsList[debt[key]]) {
                debtsList[debt[key]] = [];
            }
            debtsList[debt[key]].push(debt);
        });
        return debtsList;
    };

    render() {
        if (!this.props.isLoaded || !isLoaded(this.props.users)) {
            return <Loading />;
        }
        const debtsGroupedByPerson = this.groupByPerson(
            this.props.iOweSomeone.filter(debt => debt.personWhoOwes === this.props.auth.uid),
            'personBeingOwed',
        );
        const debtsOwedGroupedByPerson = this.groupByPerson(
            this.props.someoneOwesMe.filter(debt => debt.personBeingOwed === this.props.auth.uid),
            'personWhoOwes',
        );
        let totalSum = 0;
        let totalPaidSum = 0;
        let totalOwedSum = 0;
        return (
            <div>
                <h1>Your debts list</h1>
                {this.props.iOweSomeone.length === 0 && <div>No debts</div>}
                <div className="debtsGroup">
                    <h2>Not paid debts you need to pay</h2>
                    <div className="debts">
                        {Object.keys(debtsGroupedByPerson).map(debtByPerson => {
                            let sum = 0;
                            const debts = debtsGroupedByPerson[debtByPerson].filter(
                                debt => !debt.personWhoOwesPaid && !debt.personBeingOwedPaid,
                            );
                            if (debts.length === 0) {
                                return null;
                            }
                            return (
                                <div key={debtByPerson} className="debt">
                                    {this.props.users
                                        .filter(user => user.key === debtByPerson)
                                        .map(user => (
                                            <div className="groupAvatar" key="group">
                                                <img
                                                    src={user.value.avatarUrl}
                                                    alt={user.value.displayName}
                                                />
                                                {user.value.displayName} ({user.value.accountNumber}
                                                )
                                            </div>
                                        ))}
                                    <p className="debtsPerPersonP">Debts list:</p>
                                    <table className="debtsPerPerson">
                                        <tbody>
                                            {debts
                                                .sort((a, b) => a.date - b.date)
                                                .map(debt => {
                                                    sum += +debt.debt;
                                                    totalSum += +debt.debt;
                                                    return (
                                                        <tr key={debt.id}>
                                                            <td className="title">{debt.title}</td>
                                                            <td className="debtAmount">
                                                                {debt.debt} PLN
                                                            </td>
                                                            <td className="date">
                                                                {formatDate(debt.date)}
                                                            </td>
                                                            <td className="button">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (
                                                                            debt.title.indexOf(
                                                                                'burger',
                                                                            ) > -1 &&
                                                                            window.tidioChatApi &&
                                                                            typeof window.tidioChatApi.track ===
                                                                                'function'
                                                                        ) {
                                                                            window.tidioChatApi.track(
                                                                                'markedaspaid',
                                                                            );
                                                                        }
                                                                        emitToSocket(
                                                                            'markDebtAsPaid',
                                                                            {
                                                                                id: debt.id,
                                                                                personWhoOwesPaid: true,
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    Mark as paid
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    <p>
                                        Sum: <strong>{sum.toFixed(2)} PLN</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="clear" />
                    {totalSum > 0 && (
                        <p>
                            You owe <strong>{totalSum.toFixed(2)} PLN</strong>
                        </p>
                    )}
                    {totalSum === 0 && <p>No debts to pay</p>}
                </div>
                <div className="debtsGroup">
                    <h2>Debts you already paid</h2>
                    <div className="debts">
                        {Object.keys(debtsGroupedByPerson).map(debtByPerson => {
                            let sum = 0;
                            const debts = debtsGroupedByPerson[debtByPerson].filter(
                                debt => debt.personWhoOwesPaid && !debt.personBeingOwedPaid,
                            );
                            if (debts.length === 0) {
                                return null;
                            }
                            return (
                                <div key={debtByPerson} className="debt">
                                    {this.props.users
                                        .filter(user => user.key === debtByPerson)
                                        .map(user => (
                                            <div className="groupAvatar" key="group">
                                                <img
                                                    src={user.value.avatarUrl}
                                                    alt={user.value.displayName}
                                                />
                                                {user.value.displayName}
                                            </div>
                                        ))}
                                    <p className="debtsPerPersonP">Debts list:</p>
                                    <table className="debtsPerPerson">
                                        <tbody>
                                            {debts
                                                .sort((a, b) => a.date - b.date)
                                                .map(debt => {
                                                    sum += +debt.debt;
                                                    totalPaidSum += +debt.debt;
                                                    return (
                                                        <tr key={debt.id}>
                                                            <td className="title">{debt.title}</td>
                                                            <td className="debtAmount">
                                                                {debt.debt} PLN
                                                            </td>
                                                            <td className="date">
                                                                {formatDate(debt.date)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    <p>
                                        Sum: <strong>{sum.toFixed(2)} PLN</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="clear" />
                    {1 === 2 && totalPaidSum > 0 && (
                        <p>
                            *ZBUGOWAN*You owed and paid <strong>{totalSum.toFixed(2)} PLN</strong>
                            *ZBUGOWNAE*
                        </p>
                    )}
                    {totalPaidSum === 0 && <p>No debts paid</p>}
                </div>
                <div className="debtsGroup">
                    <h2>Someone owes you</h2>
                    <div className="debts">
                        {Object.keys(debtsOwedGroupedByPerson).map(debtByPerson => {
                            let sum = 0;
                            const debts = debtsOwedGroupedByPerson[debtByPerson].filter(
                                debt => !debt.personBeingOwedPaid,
                            );
                            if (debts.length === 0) {
                                return null;
                            }
                            return (
                                <div key={debtByPerson} className="debt">
                                    {this.props.users
                                        .filter(user => user.key === debtByPerson)
                                        .map(user => (
                                            <div className="groupAvatar" key="group">
                                                <img
                                                    src={user.value.avatarUrl}
                                                    alt={user.value.displayName}
                                                />
                                                {user.value.displayName}
                                            </div>
                                        ))}
                                    <p className="debtsPerPersonP">Debts list:</p>
                                    <table className="debtsPerPerson">
                                        <tbody>
                                            {debts
                                                .sort((a, b) => a.date - b.date)
                                                .map(debt => {
                                                    sum += +debt.debt;
                                                    totalOwedSum += +debt.debt;
                                                    return (
                                                        <tr key={debt.id}>
                                                            <td className="title">{debt.title}</td>
                                                            <td className="debtAmount">
                                                                {debt.debt} PLN
                                                            </td>
                                                            <td className="date">
                                                                {formatDate(debt.date)}
                                                            </td>
                                                            <td className="status">
                                                                {debt.personWhoOwesPaid === 1 &&
                                                                    'Marked as paid'}
                                                            </td>
                                                            <td className="button">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        emitToSocket(
                                                                            'markDebtAsPaid',
                                                                            {
                                                                                id: debt.id,
                                                                                personBeingOwedPaid: true,
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    Mark as paid
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    <p>
                                        Sum: <strong>{sum.toFixed(2)} PLN</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="clear" />
                    {totalOwedSum > 0 && (
                        <p>
                            You are owed <strong>{totalOwedSum.toFixed(2)} PLN</strong>
                        </p>
                    )}
                    {totalOwedSum === 0 && <p>No debts someone needs to pay</p>}
                </div>
            </div>
        );
    }
}

DebtsList.propTypes = {
    debts: PropTypes.arrayOf(PropTypes.object),
};
DebtsList.defaultProps = {
    debts: undefined,
};
const enhance = compose(
    firebaseConnect(['users']),
    connect((state, b) => ({
        users: state.firebase.ordered.users,
        auth: state.firebase.auth,
        someoneOwesMe: getSomeoneOwesMe(state),
        iOweSomeone: getIOweSomeone(state),
        isLoaded: getIsLoaded(state),
    })),
);
export default enhance(DebtsList);
