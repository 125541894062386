import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import { emitToSocket } from '../helpers';
import { getBurgerStats, getBurgerHistory } from '../store/selectors';
import Loading from './Loading';

const Burgers = (props) => {
    const historyDownloaded = props.history !== undefined;
    React.useEffect(() => {
        setTimeout(() => emitToSocket('getBurgerHistory'), 500);
        setTimeout(() => {
            if (!historyDownloaded) {
                emitToSocket('getBurgerHistory');
            }
        }, 2500);
        setTimeout(() => {
            if (!historyDownloaded) {
                emitToSocket('getBurgerHistory');
            }
        }, 5000);
    }, [historyDownloaded]);

    const additionals = React.useMemo(() => {
        if (!props.history) {
            return [];
        }
        return props.history.fries
            .map((item) => ({
                ...item,
                type: 'fries',
            }))
            .concat(
                props.history.cola.map((item) => ({
                    ...item,
                    type: 'cola',
                })),
            )
            .concat(
                props.history.batatyFries.map((item) => ({
                    ...item,
                    type: 'batatyFries',
                })),
            )
            .concat(
                props.history.cheeseChivesFries.map((item) => ({
                    ...item,
                    type: 'cheeseChivesFries',
                })),
            )
            .sort((a, b) => a.dateStart - b.dateStart);
    }, [props.history]);

    if (!props.history) {
        return <Loading />;
    }

    return (
        <div className="ordersHistory">
            <h2>Your orders history</h2>
            <h3>Burgers</h3>
            <table>
                <thead>
                    <tr>
                        <td>Burger</td>
                        <td>Dodatki</td>
                        <td>Date</td>
                        <td>Place</td>
                    </tr>
                </thead>
                <tbody>
                    {props.history.burgers.map((item) => {
                        const additionals = [];
                        if (item.boczki === 2) {
                            additionals.push('+ podwojny boczek');
                        }
                        if (item.boczki === 1) {
                            additionals.push('+ boczek');
                        }
                        if (item.jalapeno) {
                            additionals.push('+ jalapeno');
                        }
                        if (item.kotlet) {
                            additionals.push('+ kotlet');
                        }
                        if (item.bulka_zytnia) {
                            additionals.push('+ bulka zytnia');
                        }
                        if (item.pieczarki) {
                            additionals.push('+ pieczarki');
                        }
                        if (item.nachos) {
                            additionals.push('+ nachos');
                        }
                        if (item.ogorek) {
                            additionals.push('+ ogorek');
                        }
                        if (item.panierowany_ser) {
                            additionals.push('+ panierowany ser');
                        }
                        if (item.meat === 'cheese') {
                            additionals.push('panierowany_ser zamiast kotleta');
                        }
                        if (item.meat === 'no-meat') {
                            additionals.push('bez mięsa');
                        }
                        return (
                            <tr key={item.id}>
                                <td>{item.burger}</td>
                                <td>{additionals.length > 0 ? additionals.join(', ') : '-'}</td>
                                <td>{dayjs(item.dateStart).format('DD MMM YYYY')}</td>
                                <td>{item.place === 'miejsce' ? 'Bro burgers' : 'Biuro'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <h3>Additionals</h3>
            <table>
                <thead>
                    <tr>
                        <td>Type</td>
                        <td>Date</td>
                        <td>Place</td>
                    </tr>
                </thead>
                <tbody>
                    {additionals.map((item) => {
                        let name = '';
                        if (item.type === 'cola') {
                            if (item.colazero) {
                                name = 'Cola zero';
                            } else {
                                name = 'Cola';
                            }
                        }
                        if (item.type === 'batatyFries') {
                            if (item.halffries) {
                                name = 'Half sweet potatoes fries';
                            } else {
                                name = 'Sweet potatoes fries';
                            }
                        }
                        if (item.type === 'cheeseChivesFries') {
                            if (item.halffries) {
                                name = 'Half Cheese, grilled bacon, chives fries';
                            } else {
                                name = 'Cheese, grilled bacon, chives fries';
                            }
                        }
                        if (item.type === 'fries') {
                            if (item.halffries) {
                                name = 'Half fries';
                            } else {
                                name = 'Fries';
                            }
                        }
                        return (
                            <tr key={item.id}>
                                <td>{name}</td>
                                <td>{dayjs(item.dateStart).format('DD MMM YYYY')}</td>
                                <td>{item.place === 'wynos' ? 'Biuro' : 'Bro burgers'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <p>Lista zamówień od 22.10.2019</p>
        </div>
    );
};

export default connect((state) => ({
    history: getBurgerHistory(state),
}))(Burgers);
