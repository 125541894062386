import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import qs from 'qs';
import swal from 'sweetalert2';

import Option from './AddDebtFormOption';
import { emitToSocket } from '../helpers';
import Loading from './Loading';

const GravatarValue = props => (
    <div className="Select-value" title={props.value.title}>
        <span className="Select-value-label">
            <img className="avatar" alt={props.value.label} src={props.value.avatar} />
            {props.children}
        </span>
    </div>
);

class AddDebtForm extends React.Component {
    state = {
        title: '',
        debt: 0,
        person: '',
        someoneOwesMe: true,
    };

    componentDidMount() {
        const parameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (parameters.straz && parameters.miejska && parameters.wyjebal) {
            swal.showLoading();
            window.history.replaceState(null, 'Add debt', 'add-debt');
            setTimeout(() => {
                this.setState(
                    {
                        title: parameters.wyjebal,
                        person: parameters.straz,
                        debt: parameters.miejska,
                    },
                    () => {
                        setTimeout(() => this.add(), 1500);
                    },
                );
            }, 3000);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.state.person === '' &&
            isLoaded(this.props.users) &&
            !isLoaded(prevProps.users) &&
            this.getOnlyActiveUsers(this.getUsersWithoutMyUser()).length > 0
        ) {
            // eslint-disable-next-line
            this.setState({
                person: this.getOnlyActiveUsers(this.getUsersWithoutMyUser())[0].key,
            });
        }
    }

    add = () => {
        if (this.state.person === '') {
            swal.fire({
                title: 'An error occured!',
                text: "Person can't be empty",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
            });
            return false;
        }
        if (this.state.title === '') {
            swal.fire({
                title: 'An error occured!',
                text: "Title can't be empty",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
            });
            return false;
        }
        if (this.state.debt === 0) {
            swal.fire({
                title: 'An error occured!',
                text: "Debt can't be 0",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
            });
            return false;
        }
        const person =
            this.state.person || this.getOnlyActiveUsers(this.getUsersWithoutMyUser())[0].key;
        let personWhoOwes = this.props.auth.uid;
        let personBeingOwed = person;
        if (this.state.someoneOwesMe) {
            personWhoOwes = person;
            personBeingOwed = this.props.auth.uid;
        }
        // this.props.add('debts', {
        //     title: this.state.title,
        //     debt: this.state.debt,
        //     personWhoOwes,
        //     personBeingOwed,
        //     date: new Date().getTime(),
        // });
        emitToSocket('addDebt', {
            title: this.state.title,
            debt: this.state.debt,
            personWhoOwes,
            personBeingOwed,
            date: new Date().getTime(),
        });
        swal.fire({
            title: 'Debt added',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK!',
        });
        this.setState({
            title: '',
            debt: 0,
        });
    };

    getUsersWithoutMyUser = () => this.props.users.filter(user => user.key !== this.props.auth.uid);

    getOnlyActiveUsers = users => users.filter(user => user.value.active);

    render() {
        const placeholder = <span>&#128526; Select User</span>;

        if (!isLoaded(this.props.users) || !isLoaded(this.props.auth)) {
            return <Loading />;
        }
        return (
            <form className="addDebt">
                <h1>Add debt</h1>
                <label htmlFor="someoneOwesMe">
                    Who owes who
                    <select
                        id="someoneOwesMe"
                        value={this.state.someoneOwesMe}
                        onChange={e => {
                            this.setState({ someoneOwesMe: e.target.value === 'true' });
                        }}
                    >
                        <option value>Someone owes me</option>
                        <option value={false}>I owe someone</option>
                    </select>
                </label>
                <label htmlFor="person">
                    Person
                    <Select
                        id="person"
                        className="personSelect"
                        onChange={e => {
                            this.setState({ person: e.value });
                        }}
                        optionComponent={Option}
                        valueComponent={GravatarValue}
                        placeholder={placeholder}
                        options={this.getOnlyActiveUsers(this.getUsersWithoutMyUser()).map(
                            user => ({
                                label: user.value.displayName,
                                value: user.key,
                                avatar: user.value.avatarUrl,
                            }),
                        )}
                        value={this.state.person}
                        clearable={false}
                    />
                </label>
                <label htmlFor="debt">
                    Debt amount
                    <input
                        id="debt"
                        step="0.01"
                        type="number"
                        value={this.state.debt}
                        onChange={e => {
                            this.setState({ debt: e.target.value });
                        }}
                    />
                </label>
                <label htmlFor="title">
                    Title
                    <input
                        id="title"
                        type="text"
                        value={this.state.title}
                        onChange={e => {
                            this.setState({ title: e.target.value });
                        }}
                    />
                </label>
                <button onClick={this.add} type="button">
                    Add
                </button>
            </form>
        );
    }
}

AddDebtForm.propTypes = {
    add: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    auth: PropTypes.shape({
        uid: PropTypes.string,
    }).isRequired,
};
AddDebtForm.defaultProps = {
    users: undefined,
};
const enhance = compose(
    firebaseConnect(['users']),
    connect(({ firebase }, b) => ({
        users: firebase.ordered.users,
        auth: firebase.auth,
    })),
);
export default enhance(AddDebtForm);
