import React from 'react';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { getUser, emitToSocket } from '../helpers';
import Loading from './Loading';
import { getFingerPrints } from '../store/selectors';

const Admin = (props) => {
    const { users } = props;
    const fingerPrintsDownloaded = props.fingerprints !== null;
    React.useEffect(() => {
        setTimeout(() => emitToSocket('getPushTokens'), 500);
        setTimeout(() => {
            if (!fingerPrintsDownloaded) {
                emitToSocket('getPushTokens');
            }
        }, 2500);
        setTimeout(() => {
            if (!fingerPrintsDownloaded) {
                emitToSocket('getPushTokens');
            }
        }, 5000);
    }, [fingerPrintsDownloaded]);
    const tokensMap = React.useMemo(() => {
        if (!props.fingerprints) {
            return {};
        }
        const fingerprints = {};
        for (let i = 0; i < props.fingerprints.length; i += 1) {
            fingerprints[props.fingerprints[i].uid] = props.fingerprints[i].amount;
        }
        return fingerprints;
    }, [props.fingerprints]);
    if (!isLoaded(users) || !isLoaded(props.user) || !isLoaded(props.user.value)) {
        return <Loading />;
    }
    if (!props.user.value.isAdmin) {
        props.history.push('/');
        return <div>Nie jestes adminem dziku</div>;
    }

    return (
        <div>
            <h1>Admin</h1>
            <button
                type="button"
                onClick={() => {
                    emitToSocket('reloadPage');
                }}
            >
                Reload for everyone
            </button>
            <button
                type="button"
                onClick={() => {
                    emitToSocket('cleanPreviousTopics');
                }}
            >
                Clean old tokens
            </button>
            <h2>Users list</h2>
            {users.length > 0 && (
                <table className="usersList">
                    <thead>
                        <tr>
                            <td>Avatar</td>
                            <td>Name</td>
                            <td>Email</td>
                            <td>ID</td>
                            <td>account number</td>
                            <td>number of tokens in db</td>
                            <td>Activate/deactivate</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users
                            .sort((a, b) => a.value.active - b.value.active)
                            .map((user) => (
                                <tr key={user.key}>
                                    <td>
                                        <img
                                            src={user.value.avatarUrl}
                                            alt={`${user.value.displayName} avatar`}
                                        />
                                    </td>
                                    <td>{user.value.displayName}</td>
                                    <td>{user.value.email}</td>
                                    <td>
                                        <input type="text" defaultValue={user.key} />
                                    </td>
                                    <td>{user.value.accountNumber}</td>
                                    <td>{tokensMap[user.key]}</td>
                                    <td>
                                        {!user.value.active && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    props.firebase.update(`users/${user.key}`, {
                                                        active: true,
                                                    });
                                                }}
                                            >
                                                Activate
                                            </button>
                                        )}
                                        {user.value.active && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    props.firebase.update(`users/${user.key}`, {
                                                        active: false,
                                                    });
                                                }}
                                            >
                                                Deactivate
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};
Admin.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
        isAdmin: PropTypes.bool,
    }),
};
Admin.defaultProps = {
    users: [],
    user: {
        isAdmin: false,
    },
};
const enhance = compose(
    firebaseConnect(['users']),
    connect((state) => ({
        users: state.firebase.ordered.users,
        auth: state.firebase.auth,
        user:
            state.firebase.ordered.users &&
            getUser(state.firebase.ordered.users, state.firebase.auth.uid),
        fingerprints: getFingerPrints(state),
    })),
);
export default withRouter(enhance(Admin));
