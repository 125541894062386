import moment from 'moment';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import firebase from 'firebase';
import 'firebase/messaging';
import Push from 'push.js';
import Fingerprint2 from 'fingerprintjs2';
import Bowser from 'bowser';
import {
    addMyDebts,
    addDebt,
    setMyToken,
    updateDebt,
    setBurgersStats,
    setTodayOrders,
    setTodayOrdersStatus,
    setTodayHistory,
    setMenu,
    setTokens,
} from '../store/actions';

export const mapFirebaseArrayToObject = (arr) => {
    const obj = {};
    arr.forEach((element) => {
        obj[element.key] = element.value;
    });
    return obj;
};
export const getUser = (users, uid) => {
    const user = users.filter((el) => el.key === uid);
    if (user && user.length === 1) {
        return user[0];
    }
    return {};
};
export const formatDate = (seconds) => moment(seconds).format('LL');

let socket = null;

export const emitToSocket = (emitName, data) => {
    if (socket && typeof socket.emit === 'function') {
        socket.emit(emitName, data);
    }
};

let fingerprint = '';
const calculateFingerPrint = () => {
    const newFingerprintComponents = Fingerprint2.getPromise();
    newFingerprintComponents.then((data) => {
        fingerprint = Fingerprint2.x64hash128(
            data.map((component) => component.value).join(''),
            31,
        );
    });
};

if (window.requestIdleCallback) {
    requestIdleCallback(function () {
        fingerprint = calculateFingerPrint();
    });
} else {
    setTimeout(function () {
        fingerprint = calculateFingerPrint();
    }, 500);
}

export const tokenManagment = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.getBrowserName() === 'Safari') {
        return false;
    }
    const sendTokenToServer = (token) => {
        setTimeout(() => {
            emitToSocket('addTokenForUser', {
                token,
                fingerprint,
            });
        }, 1000);
    };

    const permissions = Push.Permission.get();
    if (permissions === Push.Permission.GRANTED) {
        const messaging = firebase.messaging();
        // Callback fired if Instance ID token is updated.
        messaging
            .getToken()
            .then((currentToken) => {
                if (currentToken) {
                    sendTokenToServer(currentToken);
                }
            })
            .catch((err) => {
                //
            });
        messaging.onTokenRefresh(() => {
            messaging
                .getToken()
                .then((refreshedToken) => {
                    sendTokenToServer(refreshedToken);
                })
                .catch(() => {
                    //
                });
        });

        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.setBackgroundMessageHandler` handler.
        messaging.onMessage((payload) => {
            const notificationOptions = {};
            if (payload.data.text) {
                notificationOptions.body = payload.data.text;
            }
            if (payload.data.icon) {
                notificationOptions.icon = payload.data.icon;
            }

            if (payload.data.image) {
                notificationOptions.image = payload.data.image;
            }

            if (payload.data.vibrate) {
                notificationOptions.vibrate = payload.data.vibrate.split(',');
            }

            if (payload.data.requireInteraction) {
                notificationOptions.requireInteraction = true;
            }

            if (payload.data.link) {
                notificationOptions.link = payload.data.link;
            }

            Push.create(payload.data.title, notificationOptions);
        });
    }
};

export const connectToSockets = (firebaseP, dispatch) => {
    socket = io(process.env.socketUrl);
    socket.on('connect', () => {
        firebaseP
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                socket.emit('visitorRegister', {
                    token: idToken,
                });
            })
            .catch(function (error) {
                console.log(error);
                //
            });
        socket.on('authError', function () {
            alert('auth error');
        });
        socket.on('visitorAuthorized', (data) => {
            dispatch(setMyToken(data.id));
            tokenManagment();
            socket.emit('getMyDebts');
        });
        socket.on('addingDebtFailed', () => {
            Swal.fire({
                title: 'An error occured!',
                text: 'Adding to DB failed',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
            });
        });
        socket.on('markingAsPaidFailed', () => {
            Swal.fire({
                title: 'An error occured!',
                text: 'Marking as paid failed',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK!',
            });
        });
        socket.on('myDebts', (data) => {
            dispatch(addMyDebts(data));
        });
        socket.on('newDebt', (data) => {
            dispatch(addDebt(data));
        });
        socket.on('updateDebt', (data) => {
            dispatch(updateDebt(data));
        });
        socket.on('burgerStats', (data) => {
            dispatch(setBurgersStats(data));
        });
        socket.on('todayOrders', (data) => {
            dispatch(setTodayOrders(data));
        });
        socket.on('todayOrdersStatus', (data) => {
            dispatch(setTodayOrdersStatus(data));
        });

        socket.on('burgerHistory', (data) => {
            dispatch(setTodayHistory(data));
        });

        socket.on('setFullOrderSuccess', (data) => {
            Swal.fire({
                icon: 'success',
                title: 'Order successful! 🐄',
                text: `Your order for ${data.price} zł was added  😎`,
            });
        });

        socket.on('menuDownloaded', (data) => {
            const newMenu = { ...data.menu };
            const newBurgers = {};
            Object.entries(data.menu.burgers).forEach(([key, value]) => {
                try {
                    if (value.availableOn) {
                        const date = new Date(value.availableOn);
                        const currentDate = new Date();
                        if (
                            date.getDate() === currentDate.getDate() &&
                            date.getMonth() === currentDate.getMonth() &&
                            date.getFullYear() === currentDate.getFullYear()
                        ) {
                            newBurgers[key] = value;
                        }
                    } else {
                        newBurgers[key] = value;
                    }
                } catch {
                    //
                }
            });
            newMenu.burgers = newBurgers;
            dispatch(setMenu(newMenu));
        });
        socket.on('tokensDownloaded', (data) => {
            dispatch(setTokens(data.tokens));
        });
        socket.on('reloadPage', () => {
            window.location.reload(true);
        });
    });
};
