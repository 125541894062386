import React from 'react';
import { hot } from 'react-hot-loader';
import { Route } from 'react-router-dom';
import AddDebtForm from './AddDebtForm';
import DebtsList from './DebtsList';
import '../styles/main.scss';
import AppContainer from './AppContainer';
import YourProfile from './YourProfile';
import Admin from './Admin';
import Burgers from './Burgers';
import Halloween from './Halloween';
import Orders from './Orders';
import OrderBurger from './OrderBurger';
import Push from './Push';
import BurgersHistory from './BurgersHistory';

// console.group('todo');
// console.log('mail after adding debts');
// console.log('styles');
// console.log('addDate to debts');
// console.groupEnd();
const App = () => (
    <div>
        <AppContainer>
            <Route exact path="/add-debt" component={AddDebtForm} />
            <Route exact path="/your-profile" component={YourProfile} />
            <Route exact path="/order-burger" component={OrderBurger} />
            <Route exact path="/burgers-history" component={BurgersHistory} />
            <Route exact path="/burgers" component={Burgers} />

            <Route exact path="/orders" component={Orders} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/push" component={Push} />
            <Route exact path="/halloween" component={Halloween} />
            <Route exact path="/" component={DebtsList} />
        </AppContainer>
    </div>
);

export default hot(module)(App);
