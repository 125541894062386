import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory as createHistory } from 'history';
import firebase from 'firebase/app';
import 'firebase/messaging';
// import 'firebase/firestore';
import { firebaseReducer } from 'react-redux-firebase';
import Bowser from 'bowser';
import reducer from './reducer';
import { tokenManagment } from '../helpers';
// import reducer from './reducer';
// import { locationChanged } from './actions';
// import mainSaga from './sagas';

const firebaseConfig = {
    apiKey: process.env.apiKey,
    authDomain: process.env.authDomain,
    databaseURL: process.env.databaseURL,
    projectId: process.env.projectId,
    storageBucket: process.env.storageBucket,
    messagingSenderId: process.env.messagingSenderId,
    appId: process.env.appId,
}; // from Firebase Console
firebase.initializeApp(firebaseConfig);
let messaging = null;
const browser = Bowser.getParser(window.navigator.userAgent);
if (browser.getBrowserName() !== 'Safari') {
    messaging = firebase.messaging();

    messaging.usePublicVapidKey(
        'BH45G6KF6vd3hOOLKdBB3qbWeTAKvXb-SF7je16wwxwkziekx_Y87_UI7f8re5utcSBOFAN_b8wGq96HI51HDDM',
    );
}
tokenManagment();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const basename = process.env.APP_BASENAME;
const history = createHistory({ basename });

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
}

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    debts: reducer,
});

const store = createStore(
    rootReducer,
    {}, // initial state
    composeEnhancers(applyMiddleware(...middlewares)),
);

// sagaMiddleware.run(helloSaga, getFirebase);

if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
        // eslint-disable-next-line
        const nextRootReducer = require('./reducer');
        store.replaceReducer(nextRootReducer);
    });
}
export { history };
export default store;
