import React from 'react';
import Swal from 'sweetalert2';
import { emitToSocket, getUser } from '../helpers';
import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Push = (props) => {
    if (!props.user.value.isAdmin) {
        return <div className="orders">Nie jestes adminem dziku</div>;
    }
    const day_date = new Date();
    const data = `${day_date.getDate()}${day_date.getMonth()}${day_date.getFullYear()}`;
    const [topic, setTopic] = React.useState(`burgers_${data}_all`);
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [vibrate, setVibrate] = React.useState('0,250,250,250,250,250');
    const [icon, setIcon] = React.useState('/mstile-310x310.png');
    const [image, setImage] = React.useState('');
    const [link, setLink] = React.useState('');

    return (
        <div className="pushForm">
            <div className="pre">
                <button
                    type="button"
                    onClick={() => {
                        setTitle('Burgery jadą do biura!');
                        setText('Oczekiwany przyjazd za ok. 6 min');
                    }}
                >
                    Burgery jadą
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setTitle('Burgery są w biurze!');
                        setText('Zapraszam po burgery na górę :)');
                    }}
                >
                    Burgery na miejscu
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setTitle('Burgery zamówione');
                        setText('Powinny byc w biurze okolo ');
                    }}
                >
                    Burgery będą o{' '}
                </button>
            </div>
            <div>
                <label>Title</label>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div>
                <label>Text</label>
                <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
            </div>
            <div>
                <label>Vibrate pattern</label>
                <input type="text" value={vibrate} onChange={(e) => setVibrate(e.target.value)} />
            </div>
            <div>
                <label>Icon</label>
                <input type="text" value={icon} onChange={(e) => setIcon(e.target.value)} />
            </div>
            <div>
                <label>Image</label>
                <input type="text" value={image} onChange={(e) => setImage(e.target.value)} />
            </div>
            <div>
                <label>Link</label>
                <input type="text" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>
            <div>
                <select value={topic} onChange={(e) => setTopic(e.target.value)}>
                    <option value={`burgers_${data}_all`}>Send to all todays burgers</option>
                    <option value={`burgers_${data}_miejsce`}>
                        Send to todays burgers eating in bro
                    </option>
                    <option value={`burgers_${data}_wynos`}>
                        Send to todays burgers eating in the office
                    </option>
                    <option value="everyone">Send to everyfuckingone</option>
                </select>
            </div>
            <button
                className="send"
                type="button"
                disabled={title === ''}
                onClick={() => {
                    emitToSocket('sendPushNotifications', {
                        title,
                        text,
                        vibrate,
                        icon,
                        image,
                        topic,
                        link,
                        requireInteraction: true,
                    });
                    Swal.fire({
                        title: 'Push SENDED', // PDK
                        text: 'Push has been sent',
                        icon: 'success',
                    });
                }}
            >
                SEND PUSH,
            </button>
        </div>
    );
};

Push.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
        isAdmin: PropTypes.bool,
    }),
};
Push.defaultProps = {
    users: [],
    user: {
        isAdmin: false,
    },
};
const enhance = compose(
    firebaseConnect(['users']),
    connect((state) => ({
        users: state.firebase.ordered.users,
        auth: state.firebase.auth,
        user:
            state.firebase.ordered.users &&
            getUser(state.firebase.ordered.users, state.firebase.auth.uid),
    })),
);
export default enhance(Push);
