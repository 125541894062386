import React from 'react';
import { connect } from 'react-redux';
import { emitToSocket } from '../helpers';
import { getBurgerStats } from '../store/selectors';
import Loading from './Loading';

const Burgers = props => {
    React.useEffect(() => {
        setTimeout(() => emitToSocket('getBurgerStats'), 500);
    }, []);
    if (props.burgers === null) {
        return <Loading />;
    }
    return (
        <div>
            <p>Stats since 24 January 2019</p>
            <p>
                Total burgers: {props.burgers.totalBurgers} ({props.burgers.totalSum.toFixed(2)}{' '}
                PLN)
            </p>
            <p>
                Your burgers: {props.burgers.yourBurgers} ({props.burgers.yourBurgersSum.toFixed(2)}{' '}
                PLN)
            </p>
        </div>
    );
};

export default connect(state => ({
    burgers: getBurgerStats(state),
}))(Burgers);
