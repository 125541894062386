import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isLoaded, isEmpty, firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { getUser, connectToSockets, emitToSocket } from '../helpers';
import Loading from './Loading';

class AppContainer extends React.Component {
    state = {
        socketsConnected: false,
    };

    componentDidMount() {
        //
        setTimeout(() => {
            if (window.tidioChatApi) {
                window.tidioChatApi.track(`burger${(Math.round(Math.random() * 100) % 9) + 1}`);
            }
        }, 3000);
    }

    componentDidUpdate() {
        if (
            isLoaded(this.props.auth) &&
            isLoaded(this.props.users) &&
            isLoaded(this.props.user) &&
            this.props.user &&
            isLoaded(this.props.user.value) &&
            !this.state.socketsConnected
        ) {
            this.setState({
                socketsConnected: true,
            });
            connectToSockets(this.props.firebase, this.props.dispatch);
        }
    }

    render() {
        const { isAdmin } = this.props.user.value;
        if (
            isLoaded(this.props.auth) &&
            isLoaded(this.props.users) &&
            isLoaded(this.props.user) &&
            this.props.user &&
            isLoaded(this.props.user.value)
        ) {
            if (isEmpty(this.props.auth)) {
                return (
                    <div>
                        Please log in
                        <button
                            onClick={() =>
                                this.props.firebase.login({ provider: 'google', type: 'popup' })
                            }
                        >
                            Login With Google
                        </button>
                    </div>
                );
            }
            if (this.props.user && this.props.user.value && this.props.user.value.active !== true) {
                return <div>You are not active yet</div>;
            }
        } else {
            return <Loading />;
        }
        if (!document.tidioIdentify) {
            document.tidioIdentify = {
                email: this.props.auth.email, // User email
                name: this.props.auth.displayName, // Visitor name
                distinct_id: this.props.auth.uid,
            };
            const script = document.createElement('script');
            script.src = '//code.tidio.co/uji5kny9siv3a0vzzevergxykljpu8fb.js';
            document.body.appendChild(script);
            (function xd1() {
                function onTidioChatApiReady() {
                    tidioChatApi.setFeatures({
                        customBranding:
                            'https://windykator.cannonbarbarzynca.solutions/images/8bd5d38e0018156d41e52e8e677f2287-janpawelburger.gif',
                    });
                    window.tidioChatApi.on('messageFromOperator', data => {
                        if (data.fromBot === true && !document.hidden) {
                            // Na jakiego burgera masz ochotę = start
                            if (data.message.indexOf('Na jakiego burgera masz ochot') > -1) {
                                emitToSocket('startOrder');
                            }
                            // Dodano do listy burgera "xxx" = wybor burgera
                            else if (data.message.indexOf('Dodano do listy burgera') > -1) {
                                const match = data.message.match(/"(.*?)"/gi);
                                if (match) {
                                    emitToSocket('orderChosenBurger', {
                                        burger: match[0].substr(1, match[0].length - 2),
                                    });
                                }
                            } // Wybrałeś opcję na miejscu. Pamiętaj aby ogarnąć transport! = tylko jesli ktos wybral na miejscu
                            else if (data.message.indexOf(' na miejscu.') > -1) {
                                emitToSocket('updateOrder', {
                                    place: 'bro',
                                });
                            }
                            // Nieźle dziku. 2 boczki dodane!
                            else if (data.message.indexOf('2 boczki dodane') > -1) {
                                emitToSocket('updateBurgerInOrder', {
                                    boczki: 2,
                                });
                            }
                            // Spoko, dodatkowy boczek dodany 😀
                            else if (data.message.indexOf('boczek dodany') > -1) {
                                emitToSocket('updateBurgerInOrder', {
                                    boczki: 1,
                                });
                            }
                            // mmm, kotlecik. Pewnie masa co? Kotlet dodany 😀
                            else if (data.message.indexOf('Kotlet dodany') > -1) {
                                emitToSocket('updateBurgerInOrder', {
                                    kotlet: 1,
                                });
                            }
                            // Widzę, że lubisz na ostro. Jalapeno dodane
                            else if (data.message.indexOf('Jalapeno dodane') > -1) {
                                emitToSocket('updateBurgerInOrder', {
                                    jalapeno: 1,
                                });
                            }
                            // Widzę, że lubisz pasze dla konia. Bułka żytnia dodana 😁
                            else if (data.message.indexOf('lubisz pasze dla konia') > -1) {
                                emitToSocket('updateBurgerInOrder', {
                                    bulka_zytnia: 1,
                                });
                            }
                            // To koniec opcji tuningu burgera 😁
                            else if (data.message.indexOf('To koniec opcji tuningu burgera') > -1) {
                                emitToSocket('finishBurgerInOrder');
                            }
                            // Fryteczki dodane 😁
                            else if (data.message.indexOf('Fryteczki dodane') > -1) {
                                emitToSocket('orderAddFries');
                            }
                            // Dodano następujace uwagi: {uwagi}
                            else if (data.message.indexOf('pujace uwagi') > -1) {
                                emitToSocket('orderUwagi', {
                                    text: data.message.replace('Dodano następujace uwagi: ', ''),
                                });
                            }
                            // Super! Twoje zamówienie zostało złożone
                            else if (data.message.indexOf('Super! Twoje zam') > -1) {
                                emitToSocket('finishOrder');
                                const random = Math.random();
                                if (random < 0.25) {
                                    document.body.classList.add('awesomeburger');
                                } else if (random < 0.5) {
                                    document.body.classList.add('awesomeburger2');
                                } else if (random < 0.75) {
                                    document.body.classList.add('awesomeburger3');
                                }
                                setTimeout(
                                    () =>
                                        document.body.classList
                                            .remove('awesomeburger')
                                            .remove('awesomeburger2')
                                            .remove('awesomeburger3'),
                                    10000,
                                );
                            }
                        }
                    });
                }
                if (window.tidioChatApi) {
                    window.tidioChatApi.on('ready', onTidioChatApiReady);
                } else {
                    document.addEventListener('tidioChat-ready', onTidioChatApiReady);
                }
            })();
        }
        return (
            <div>
                <div id="menu">
                    <Link to="/">Your dashboard</Link>
                    <Link to="/add-debt">Add debt</Link>
                    <Link to="/your-profile">Your profile</Link>
                    <Link to="/order-burger">Order burger!</Link>
                    <Link to="/burgers-history">Your burgers history</Link>
                    <Link to="/burgers">Burgers stats</Link>
                    {isAdmin && <Link to="/admin">Users</Link>}
                    {isAdmin && <Link to="/orders">Orders</Link>}
                    {isAdmin && <Link to="/push">Push</Link>}
                    <span className="loggedInAs">
                        {' '}
                        Hi {this.props.auth.displayName} ({this.props.auth.email}) 👋
                    </span>
                </div>
                {this.props.children}
            </div>
        );
    }
}

AppContainer.propTypes = {
    auth: PropTypes.shape({
        displayName: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    firebase: PropTypes.shape({
        login: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    user: PropTypes.shape({
        value: PropTypes.shape({
            isAdmin: PropTypes.bool,
        }),
    }),
};
AppContainer.defaultProps = {
    user: {
        value: {
            isAdmin: false,
        },
    },
};
const enhance = compose(
    firebaseConnect(['users']),
    connect(({ firebase }, b) => ({
        auth: firebase.auth,
        firebase: b.firebase,
        users: firebase.ordered.users,
        user: firebase.ordered.users && getUser(firebase.ordered.users, firebase.auth.uid),
    })),
);

export default enhance(AppContainer);
