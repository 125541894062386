import React, { useEffect } from 'react';
import './halloween.scss';

const Halloween = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace('/order-burger');
        }, 5000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return (
        <>
            <div className="spider spider1">
                <div className="spiderweb" />
                <div className="body">
                    <div className="eye left" />
                    <div className="eye right" />
                </div>
                <div className="legs left">
                    <div className="leg" />
                    <div className="leg" />
                    <div className="leg" />
                </div>
                <div className="legs right">
                    <div className="leg" />
                    <div className="leg" />
                    <div className="leg" />
                </div>
            </div>
            <div className="cont">
                <div className="pumpkin" />
                <div className="eyes" />
                <div className="mouth" />
                <div className="stub" />
                <h1 className="boo">Boo!</h1>
            </div>
            <div className="spider spider2">
                <div className="spiderweb" />
                <div className="body">
                    <div className="eye left" />
                    <div className="eye right" />
                </div>
                <div className="legs left">
                    <div className="leg" />
                    <div className="leg" />
                    <div className="leg" />
                </div>
                <div className="legs right">
                    <div className="leg" />
                    <div className="leg" />
                    <div className="leg" />
                </div>
            </div>
        </>
    );
};

export default Halloween;
