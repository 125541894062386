export const ADD_DEBT = 'ADD_DEBT';
export function addDebt(data) {
    return {
        type: ADD_DEBT,
        data,
    };
}
export const UPDATE_DEBT = 'UPDATE_DEBT';
export function updateDebt(data) {
    return {
        type: UPDATE_DEBT,
        data,
    };
}
export const ADD_MY_DEBTS = 'ADD_MY_DEBTS';
export function addMyDebts(data) {
    return {
        type: ADD_MY_DEBTS,
        data,
    };
}

export const SET_MY_TOKEN = 'SET-MY-TOKEN';
export function setMyToken(token) {
    return {
        type: SET_MY_TOKEN,
        token,
    };
}
export const SET_BURGERS_STATS = 'SET_BURGERS_STATS';
export function setBurgersStats(data) {
    return {
        type: SET_BURGERS_STATS,
        data,
    };
}
export const SET_TODAY_ORDERS = 'SET_TODAY_ORDERS';
export function setTodayOrders(data) {
    return {
        type: SET_TODAY_ORDERS,
        data,
    };
}

export const SET_TODAY_ORDERS_STATUS = 'SET_TODAY_ORDERS_STATUS';
export function setTodayOrdersStatus(data) {
    return {
        type: SET_TODAY_ORDERS_STATUS,
        data,
    };
}

export const SET_HISTORY = 'SET_HISTORY';
export function setTodayHistory(history) {
    return {
        type: SET_HISTORY,
        history,
    };
}

export const SET_MENU = 'SET_MENU';
export function setMenu(menu) {
    return {
        type: SET_MENU,
        menu,
    };
}

export const SET_TOKENS = 'SET_TOKENS';
export function setTokens(tokens) {
    return {
        type: SET_TOKENS,
        tokens,
    };
}

