// import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import './styles/main.scss';
import { firebaseReducer, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from 'firebase/app';
import App from './components/App';
import store from './store';

// OfflinePluginRuntime.install();
const rrfConfig = { userProfile: 'users' }; // react-redux-firebase config

// const reduxFirebase = {
//     userProfile: 'users',
//     enableLogging: 'false',
// };
// firebase: firebaseReducer,

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance, // <- needed if using firestore
};

const initBugs = () => {
    const targethead = window.document.getElementsByTagName('head')[0];
    let loadedSpiders = false;
    const jst = window.document.createElement('script');
    jst.async = true;
    jst.type = 'text/javascript';
    jst.src = '/bugs/bug-min.js';
    jst.onload = jst.onreadystatechange = function () {
        if (!loadedSpiders && (!this.readyState || this.readyState == 'complete')) {
            loadedSpiders = true;
            // start fire the JS.
            new BugController({ zoom: 5, imageSprite: '/bugs/fly-sprite.png', minBugs: 5 });
            new SpiderController({ zoom: 5, imageSprite: '/bugs/spider-sprite.png' });
        }
    };
    targethead.appendChild(jst);
};

const currentDay = new Date().getDate();
const currentMonth = new Date().getMonth();

if (currentMonth === 9 && currentDay > 27 && currentDay <= 31) {
    initBugs();
}

const mountingDomNode = document.getElementById('app');
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <ReactReduxFirebaseProvider {...rrfProps}>
                <App />
            </ReactReduxFirebaseProvider>
        </BrowserRouter>
    </Provider>,
    mountingDomNode,
);
