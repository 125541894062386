export const getIOweSomeone = (state) => {
    return state.debts.iOweSomeone;
};

export const getSomeoneOwesMe = (state) => {
    return state.debts.someoneOwesMe;
};

export const getIsLoaded = (state) => {
    return state.debts.isLoaded;
};
export const getBurgerStats = (state) => {
    if (!state.debts) {
        return null;
    }
    return state.debts.burgerStats;
};

export const getTodayOrders = (state) => {
    return state.debts.todayOrders;
};

export const getTodayOrdersStatus = (state) => {
    return state.debts.todayOrdersStatus;
};

export const getMenu = (state) => {
    return state.debts.menu;
};

export const getMyId = (state) => state.debts.myId;

export const getBurgerHistory = (state) => state.debts.history;

export const getFingerPrints = (state) => state.debts.tokens;
