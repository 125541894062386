import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { mapFirebaseArrayToObject, getUser } from '../helpers';
import Loading from './Loading';

class YourProfile extends React.Component {
    state = {
        accountNumber: '',
    };

    constructor(props) {
        super();
        if (props.user && props.user.value) {
            this.state = {
                accountNumber: props.user.value.accountNumber,
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (isLoaded(this.props.user) && !isLoaded(prevProps.user)) {
            this.setState({
                accountNumber: this.props.user.value.accountNumber || '',
            });
        }
        // if (
        //     this.state.accountNumber !== '' &&
        //     this.props.user.accountNumber &&
        //     !this.state.accountNumberChanged
        // ) {
        //     this.setState({
        //         accountNumber: this.props.user.accountNumber,
        //     });
        // }
    }

    onSubmit = e => {
        e.preventDefault();
        this.props.firebase.update(`users/${this.props.auth.uid}`, {
            accountNumber: this.state.accountNumber,
        });
    };

    onAccountNumberChange = e => {
        this.setState({
            accountNumber: e.target.value,
        });
    };

    render() {
        if (!isLoaded(this.props.user)) {
            return <Loading />;
        }
        return (
            <form onSubmit={this.onSubmit}>
                <h1>Your profile</h1>
                <h2>Details</h2>
                <label htmlFor="account_number">
                    <input
                        type="text"
                        id="account_number"
                        value={this.state.accountNumber}
                        onChange={this.onAccountNumberChange}
                    />
                    Account number
                </label>
                {/* <h2>Notifications</h2>
                <label htmlFor="send_email">
                    <input type="checkbox" id="send_email" /> Send email on new debt
                </label>
                <label htmlFor="send_push">
                    <input type="checkbox" id="send_push" /> Send push notification on new debt
                </label> */}
                <button type="submit">SAVE</button>
            </form>
        );
    }
}

YourProfile.propTypes = {
    auth: PropTypes.shape({
        displayName: PropTypes.string,
    }).isRequired,
    firebase: PropTypes.shape({
        login: PropTypes.func.isRequired,
    }).isRequired,
};

const enhance = compose(
    // firestoreConnect(),
    firebaseConnect(['users']),
    connect(({ firebase }, b) => ({
        auth: firebase.auth,
        firebase: b.firebase,
        user: firebase.ordered.users && getUser(firebase.ordered.users, firebase.auth.uid),
    })),
);

export default enhance(YourProfile);
