import {
    ADD_MY_DEBTS,
    SET_MY_TOKEN,
    ADD_DEBT,
    UPDATE_DEBT,
    SET_BURGERS_STATS,
    SET_TODAY_ORDERS,
    SET_TODAY_ORDERS_STATUS,
    SET_HISTORY,
    SET_MENU,
    SET_TOKENS,
} from './actions';

const defaultState = {
    myId: null,
    someoneOwesMe: [],
    iOweSomeone: [],
    isLoaded: false,
    burgerStats: null,
    todayOrders: null,
    todayOrdersStatus: undefined,
    history: undefined,
    menu: null,
    tokens: null,
};

export default function reducer(state = defaultState, action = {}) {
    switch (action.type) {
        case ADD_MY_DEBTS: {
            const obj = {};
            const mergedSomeoneOwesMe = [...state.someoneOwesMe, ...action.data.someoneOwesMe];
            for (let i = 0, len = mergedSomeoneOwesMe.length; i < len; i += 1)
                obj[mergedSomeoneOwesMe[i].id] = mergedSomeoneOwesMe[i];

            const uniqueSomeoneOwesMe = [];
            for (let i = 0; i < Object.keys(obj).length; i += 1) {
                uniqueSomeoneOwesMe.push(obj[Object.keys(obj)[i]]);
            }

            const obj2 = {};
            const mergedIOweSomeone = [...state.iOweSomeone, ...action.data.iOweSomeone];
            for (let i = 0, len = mergedIOweSomeone.length; i < len; i += 1)
                obj2[mergedIOweSomeone[i].id] = mergedIOweSomeone[i];

            const uniqueIOweSomeone = [];
            for (let i = 0; i < Object.keys(obj2).length; i += 1) {
                uniqueIOweSomeone.push(obj2[Object.keys(obj2)[i]]);
            }
            return Object.assign(state, {
                iOweSomeone: uniqueIOweSomeone,
                someoneOwesMe: uniqueSomeoneOwesMe,
                isLoaded: true,
            });
        }
        case ADD_DEBT: {
            const iOweSomeone = [...state.iOweSomeone.filter((debt) => debt.id !== action.data.id)];
            const someoneOwesMe = [
                ...state.someoneOwesMe.filter((debt) => debt.id !== action.data.id),
            ];
            if (state.myId === action.data.personWhoOwes) {
                iOweSomeone.push(action.data);
            } else {
                someoneOwesMe.push(action.data);
            }
            return Object.assign(state, {
                iOweSomeone,
                someoneOwesMe,
            });
        }
        case UPDATE_DEBT: {
            return Object.assign(state, {
                iOweSomeone: [
                    ...state.iOweSomeone.map((debt) => {
                        if (debt.personWhoOwes === state.myId && debt.id === action.data.id) {
                            return action.data;
                        }
                        return debt;
                    }),
                ],
                someoneOwesMe: [
                    ...state.someoneOwesMe.map((debt) => {
                        if (debt.personBeingOwed === state.myId && debt.id === action.data.id) {
                            return action.data;
                        }
                        return debt;
                    }),
                ],
            });
        }
        case SET_MY_TOKEN: {
            return Object.assign(state, {
                myId: action.token,
            });
        }
        case SET_BURGERS_STATS: {
            return Object.assign(state, {
                burgerStats: action.data,
            });
        }
        case SET_TODAY_ORDERS: {
            return Object.assign(state, {
                todayOrders: action.data,
            });
        }
        case SET_TODAY_ORDERS_STATUS: {
            return Object.assign(state, {
                todayOrdersStatus: action.data,
            });
        }
        case SET_HISTORY: {
            return Object.assign(state, {
                history: action.history,
            });
        }
        case SET_MENU: {
            return Object.assign(state, {
                menu: action.menu,
            });
        }
        case SET_TOKENS: {
            return Object.assign(state, {
                tokens: action.tokens,
            });
        }
        default: {
            return state;
        }
    }
}
